<template>
  <div class="all">
    <AppHeader/>
    <div class="main">
      <el-row :gutter="50">
        <el-col :span="16" v-loading="loader">
          <div style="border: 1px solid #e5e5e5;border-radius: 4px;">
            <video id="video" ref="videoPlay" controls autoplay controlslist="nodownload" oncontextmenu="return false" preload="auto">
              <source :src="a.videoUrl" type="video/mp4">
            </video>

            <div class="details">
              <h3>{{ a.title }}</h3>
              <div class="down">
                <div class="text">
                  <p style="font-weight: 550">类型：<span style="color: #00a5ff;font-weight: 500">{{ a.tt }}</span></p>
                  <p style="font-weight: 550">级别：<span style="color: #00a5ff;font-weight: 500">{{ a.lt }}</span></p>
                </div>
                <div class="text">
                  <p style="font-weight: 550">主要肌肉群：<span style="color: #00a5ff;font-weight: 500">{{ a.zt }}</span>
                  </p>
                  <p style="font-weight: 550">其他肌肉：<span style="color: #00a5ff;font-weight: 500">{{ a.ct }}</span>
                  </p>
                </div>
                <div class="text">
                  <p style="font-weight: 550">器械要求：<span style="color: #00a5ff;font-weight: 500">{{ a.kt }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="action"
               style="background: #fff;margin-top:20px;text-align: left;padding: 10px 5%;border: 1px solid #e5e5e5;border-radius: 4px;">
            <h3 style="margin-bottom: 10px;">训练动作</h3>
            <el-row :gutter="20" v-for="(action, index) of courses" :key="index">
              <el-col :span="6" v-for="(item,i) of action" :key="item.id">
                <div @click="actionInfo(item)" class="actionShow">
                  <el-image :src="item.img" lazy style="width:100%;display: block"/>
                </div>
                <p>{{i+1}}.{{ item.title }}</p>
              </el-col>
            </el-row>
          </div>

          <div class="action" v-viewer
               style="background: #fff;margin-top:20px;text-align: left;padding: 10px 5%;border: 1px solid #e5e5e5;border-radius: 4px;">
            <h3 style="margin-bottom: 10px;">动作要领图</h3>
            <div style="width:100%">
              <div style="float: left;;width: 33%;">
                <el-image :src="a.gistGif" lazy style="width: 100%;"/>
              </div>
              <div style="float: left;;width: 33%;margin-left: 0.5%;">
                <el-image :src="img1" lazy style="width: 100%;"/>
                <p>{{ title1 }}</p>
              </div>
              <div style="float: left;width: 33%;margin-left: 0.5%;">
                <el-image :src="img2" lazy style="width: 100%;"/>
                <p>{{ title2 }}</p>
              </div>
              <div class="clear"/>
            </div>
          </div>

          <div
              style="background: #fff;width: 100%;margin-top: 20px;margin-bottom:20px;border: 1px solid #e5e5e5;border-radius: 4px;">
            <div class="pic" style="float: left;width: 49.5%;border-right: 1px solid #e5e5e5;">
              <div style="padding: 20px" v-viewer>
                <h3 style="margin-top: 0;margin-bottom: 10px">主要肌肉示意图</h3>
                <el-image :src="a.notion" lazy style="width: 100%;"/>
              </div>
            </div>
            <div style="float: left;width: 49.5%;text-align: left">
              <div style="padding: 20px;">
                <h3 style="margin-top: 0;margin-bottom: 10px">动作要领</h3>
                <p v-html="intro"/>
              </div>
            </div>
            <div class="clear"></div>
          </div>
        </el-col>
        <el-col :span="8" >
          <div style="text-align: left;margin-right: 5%;min-width: 227px;">
            <el-image :src="planInfo.img" lazy style="width: 100%;"/>
            <h3 style="margin: 10px 0">{{ planInfo.title }}</h3>
            <p style="text-indent: 2em;">{{ planInfo.desc }}</p>
            <el-collapse v-model="activeNames" style="width: 100%;background: rgba(0,0,0,0);margin-top: 20px">
              <el-collapse-item title="课程列表" name="1">
                <div v-for="(planCourse,i) in planCourses" @click="course(planCourse.courseId)" class="course">
                  第{{i+1}}节 {{ planCourse.title }}
                </div>
              </el-collapse-item>
            </el-collapse>

            <div
                style="width: 100%;margin-top: 10px;border: 1px solid #e5e5e5;border-radius: 4px;padding:15px;padding-bottom:40px;display: flex;background: #e7e7e7">
              <div style="width: 100%;">
                <div style="height:50%;display: flex;align-items: center;">
                  <el-image :src="require('../../assets/App/2.png')" lazy
                       style="height: 5.5vw;width:5.5vw;min-width:53px;min-height:53px;float: left"/>
                  <div style="float: left;text-align: left;margin-left: 2%">
                    <h3 style="line-height: 30px;">双动APP</h3>
                    <p style="font-weight: 500;">下载双动手机版，随时开练！</p>
                  </div>
                </div>

                <div style="clear: both"></div>

                <div style="height: 50%;padding-top: 10px;">
                  <div style="float: left;">
                    <a href="https://apps.apple.com/cn/app/id1459468902">
                      <el-image class="opacity" lazy :src="require('../../assets/App/3.png')"
                           style="display:block;width:11.5vw;height:3.33vw;min-width: 110px;min-height: 32px"/>
                    </a>
                    <div style="height: 1vw;min-height: 10px"></div>
                    <a href="https://app.oss.toodo.com.cn/apk/toodo_app.apk">
                      <el-image class="opacity" :src="require('../../assets/App/4.png')"
                           lazy style="display:block;width:11.5vw;height:3.33vw;min-width: 110px;min-height: 32px"/>
                    </a>
                  </div>
                  <el-image :src="require('../../assets/App/5.png')" lazy
                       style="margin-left:20px;width:7.66vw;height:7.66vw;min-height: 73px;min-width: 73px"/>
                </div>
              </div>
            </div>

          </div>
        </el-col>
      </el-row>
    </div>
    <Footer/>
  </div>
</template>

<script>
import AppHeader from "../../components/AppHeader";
import Footer from "../../components/Footer";

export default {
  name: "Course2",
  data() {
    return {
      id: null,
      data: [],
      title1: null,
      title2: null,
      img1: null,
      img2: null,
      activeNames: '1',
      planInfo: [],
      planCourses: [],
      loader: false,
      courseActions: [],
      actionData: [],
      a: {},
      intro: "",
    }
  },
  components: {
    AppHeader, Footer
  },
  computed: {
    courses() {
      const courses = []
      this.actionData.forEach((item, index) => {
        const course = Math.floor(index / 4)//3代表3条为一行，随意更改
        if (!courses[course]) {
          courses[course] = []
        }
        courses[course].push(item)
      })
      return courses
    }
  },
  methods: {
    videoPlayback() {
      let video = document.getElementById('video');
      video.setAttribute("autoplay", "autoplay")
      video.play();
    },

    //请求右边的课程列表
    plan(query) {
      this.loader = true;
      this.$axios.post('web-plan-info', {id: query}).then(res => {
        this.loader = false;
        this.planInfo = res.data.data;
        console.log(res.data.data.planCourses)
        this.planCourses = res.data.data.planCourses;
        let defaultId = res.data.data.planCourses[0].courseId;
        this.course(defaultId);
      }).catch(e => {
        this.loader = false;
        console.log(e)
      })
    },

    //请求动作列表，即中间的训练动作
    course(courseId) {
      this.loader = true;
      this.$axios.post('web-course-info', {id: courseId}).then(res => {
        this.loader = false;
        this.actionData = res.data.data.courseActions;
        let defaultItem = res.data.data.courseActions[0];
        this.actionInfo(defaultItem);
      }).catch(e => {
        this.loader = false;
        console.log(e)
      })
    },

    //处理展示某个训练动作的详情
    actionInfo(action) {
      this.a = action;
      let intros = JSON.parse(action.intro);
      let introArray=[],i=0;
      for (i; i<intros.length; i++){
        introArray +=intros[i] + "<br/>";
      }
      this.intro = introArray;
      let gistInfo = JSON.parse(action.gistInfo);
      this.$nextTick(() => {
        this.$refs.videoPlay.load();
      })
      this.title1 = gistInfo[0].title;
      this.title2 = gistInfo[1].title;
      this.img1 = gistInfo[0].img;
      this.img2 = gistInfo[1].img;
    },
  },

  created() {
    this.plan(this.$route.query.id);
  },

  mounted() {
  },
}
</script>

<style scoped>
.clear {
  clear: both
}

.course:hover,.course:active{
  color: rgb(80, 155, 90);
}

.actionShow:hover,.actionShow:active{
  box-shadow: 0 0 8px 3px #d7d7d7;
}

.all {
  min-width: 960px;
  background: #f2f2f2;
}

.main {
  margin: 60px 0 0 5%;
  padding-top: 20px;
  padding-bottom: 20px;
}

#video {
  width: 100%;
  display: block;
}

.details {
  background: #fff;
  width: 100%;
  padding: 10px 5%;
  text-align: left
}

.down {
  border-top: 1px solid #e5e5e5;
  width: 100%;
  position: relative;
  height: 100px;
  margin: 10px 0 0 0;
}


>>>.el-collapse-item__content{
  padding-bottom: 0;
}

>>> .el-collapse-item__header {
  background: #e5e5e5;
}

>>> .el-collapse-item__wrap {
  background: rgba(0, 0, 0, 0);
}

.text {
  width: 25%;
  float: left;
}

.text p {
  margin-top: 10px;
}

.opacity:active {
  opacity: 0.85;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  background: none;
  text-decoration: none;
  color: #3e3e3e;
}
</style>
